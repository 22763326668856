// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-follow-us-jsx": () => import("./../../../src/pages/follow-us.jsx" /* webpackChunkName: "component---src-pages-follow-us-jsx" */),
  "component---src-pages-get-a-bottle-jsx": () => import("./../../../src/pages/get-a-bottle.jsx" /* webpackChunkName: "component---src-pages-get-a-bottle-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-larboard-jsx": () => import("./../../../src/pages/larboard.jsx" /* webpackChunkName: "component---src-pages-larboard-jsx" */),
  "component---src-pages-leaderboard-jsx": () => import("./../../../src/pages/leaderboard.jsx" /* webpackChunkName: "component---src-pages-leaderboard-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-map-docs-jsx": () => import("./../../../src/pages/map/docs.jsx" /* webpackChunkName: "component---src-pages-map-docs-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-map-list-jsx": () => import("./../../../src/pages/map/list.jsx" /* webpackChunkName: "component---src-pages-map-list-jsx" */),
  "component---src-pages-offline-jsx": () => import("./../../../src/pages/offline.jsx" /* webpackChunkName: "component---src-pages-offline-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-shallop-jsx": () => import("./../../../src/pages/shallop.jsx" /* webpackChunkName: "component---src-pages-shallop-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

