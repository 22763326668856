module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pinavo Maps","short_name":"Pinavo Maps","start_url":"/","background_color":"#0b182a","theme_color":"#0b182a","display":"standalone","orientation":"portrait-primary","icon":"src/images/icon.png","crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Pinavo","titleTemplate":"Learn a new way | %s","description":"Community map of water resources (not only) for travelers who think environment-friendly.","url":"https://www.pinavo.cz","image":"/pinavo_meta_image.jpg","openGraph":{"title":"Pinavo","description":"Community map of water resources (not only) for travelers who think environment-friendly.","type":"website","locale":"en_GB","url":"https://www.pinavo.cz","site_name":"Pinavo","images":[{"url":"/pinavo_meta_image.jpg","width":1200,"height":628,"alt":"Pinavo"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-186078527-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-186078527-1","enableDevelopment":false,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":false,"gaOptions":{"sampleRate":10}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
